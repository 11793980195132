import React, { lazy, Suspense } from 'react';

import ReactDOM from 'react-dom';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';

const App = lazy(() => import('./views/App'));
const Main = lazy(() => import('./views/Main'));
const Start = lazy(() => import('./views/Start'));
const Planet = lazy(() => import('./views/Planet'));
const NotFound = lazy(() => import('./views/NotFound'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        element: <Main />,
      },
      {
        path: '/start',
        element: <Start />,
      },
      {
        path: '/planet',
        element: <Planet />,
      },
      {
        path: '*',
        element: <NotFound />
      }
    ],
  },
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
);
